import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "players"
    }}>{`Players`}</h1>
    <h2 {...{
      "id": "spawn"
    }}>{`Spawn`}</h2>
    <p>{`Players start the game in their team spawn with 1 Armor, 1 Rocket and 30 Coppers.
Regen pads are available near their spawn to recover health and buy items. `}<strong parentName="p">{`You can buy items only on regen pads`}</strong>{`.
When you’re low on health or need to spend your `}<a parentName="p" {...{
        "href": "/players#coppers"
      }}>{`coppers`}</a>{` return on this regen pads. When a player is on a regen spawn, he is invincible.`}</p>
    <p><img alt="Regen Pads" src={require("../public/regen-pads.jpg")} /></p>
    <p style={{
      "textAlign": "center",
      "fontSize": "0.8em",
      "marginTop": "-25px"
    }}><em>Regen Pads</em></p>
    <h2 {...{
      "id": "player-screen"
    }}>{`Player Screen`}</h2>
    <p><img alt="Player Screen" src={require("../public/player-screen.png")} /></p>
    <p>{`1 : Number of `}<a parentName="p" {...{
        "href": "/bots#guardians"
      }}>{`guardians`}</a>{` in each team`}<br parentName="p"></br>{`
`}{`2 : Your coppers`}<br parentName="p"></br>{`
`}{`3 : Last coppers gained`}<br parentName="p"></br>{`
`}{`4 : Number of damage you deal`}<br parentName="p"></br>{`
`}{`5 : `}<a parentName="p" {...{
        "href": "/poles#bonus-poles"
      }}>{`Bonus Pole`}</a>{` effect and loading state`}</p>
    <h2 {...{
      "id": "characteristics"
    }}>{`Characteristics`}</h2>
    <p>{`Players have nine characteristics: `}</p>
    <ul>
      <li parentName="ul">{`Armor`}<br parentName="li"></br>
        <em parentName="li">{`Amount of life a player has.`}</em></li>
      <li parentName="ul">{`Weapon`}<br parentName="li"></br>
        <em parentName="li">{`Type of weapon the player uses (Rocket, Laser, Nucleus or Arrow).`}</em></li>
      <li parentName="ul">{`Ammo Power`}<br parentName="li"></br>
        <em parentName="li">{`Damage the player deals when he hits a bot or another player.`}</em></li>
      <li parentName="ul">{`Ammo Max`}<br parentName="li"></br>
        <em parentName="li">{`Number of ammo the player has.`}</em></li>
      <li parentName="ul">{`Ammo Gain`}<br parentName="li"></br>
        <em parentName="li">{`Player's weapon reload speed.`}</em></li>
      <li parentName="ul">{`Stamina Max`}<br parentName="li"></br>
        <em parentName="li">{`The more this value is high, the more the player has stamina.`}</em></li>
      <li parentName="ul">{`Shield`}<br parentName="li"></br>
        <em parentName="li">{`Damage you take are reduced by your shield. You can't reduce damage dealt under 1.`}</em></li>
      <li parentName="ul">{`Life Steal`}<br parentName="li"></br>
        <em parentName="li">{`When you deal damage to opponents (guardians excepted) or gold bots, you recover a number of Armors equals to your Life Steal value.`}</em></li>
      <li parentName="ul">{`Armor Regen`}<br parentName="li"></br>
        <em parentName="li">{`Passively regenerates your armors over time.`}</em></li>
    </ul>
    <p>{`Your characteristics are displayed on the `}<a parentName="p" {...{
        "href": "/shop"
      }}>{`shop`}</a>{` screen.`}<br parentName="p"></br>{`
`}<img alt="Characteristics" src={require("../public/characteristics.png")} /></p>
    <h2 {...{
      "id": "coppers"
    }}>{`Coppers`}</h2>
    <p>{`Coppers are the in-game currency of `}<em parentName="p">{`ManiaLegends`}</em>{`. They are used to buy items in the `}<a parentName="p" {...{
        "href": "/shop"
      }}>{`shop`}</a>{` that provide bonus stats and abilities.
Players start the game with 30 Coppers and gain `}<strong parentName="p">{`1 Copper every 2 seconds`}</strong>{`. Players can gain more coppers eliminating `}<a parentName="p" {...{
        "href": "/bots"
      }}>{`bots, guardians, gold bots`}</a>{` and other players and capturing the `}<a parentName="p" {...{
        "href": "/poles#bonus-pole"
      }}>{`bonus pole`}</a>{`.`}</p>
    <p>{`Your coppers are shown at the bottom-right of your screen:`}<br parentName="p"></br>{`
`}<img alt="Coppers" src={require("../public/coppers.png")} /></p>
    <h2 {...{
      "id": "respawn"
    }}>{`Respawn`}</h2>
    <p>{`If a player dies, he is removed from active play until a respawn timer counts down to zero, where they are then respawned in their team spawn. Respawn time depends of the number of guardians: the fewer guardians, the longer the respawn time.`}</p>
    <h2 {...{
      "id": "player-elimination"
    }}>{`Player Elimination`}</h2>
    <p>{`If a player kills another player, he gains 30 coppers and his teammates gains 20 coppers. You get more coppers if the player you have eliminated has more coppers than you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      